<template>
  <div
    class="Item"
    :class="['layout-' + layout, empty ? 'empty' : '']">
    <div class="body">
      <div
        class="initial"
        :class="['initial--' + initial.type]">
        <div
          v-if="initial.type === 'image'"
          class="initialImage"
          :style="{ backgroundImage: `url(${initial.value})` }" />
        <div v-else>
          {{ initial.value }}
        </div>
      </div>
      <!-- <div class="divider"></div> -->
      <div class="tests">
        <Test
          v-for="(test, i) of tests"
          :key="i"
          v-bind="test" />
      </div>
    </div>
    <div class="footer">
      <div>{{ $t('memorize') }}: {{ (memorizeTime / 1000).toFixed(2) }} {{ $t('seconds') }}</div>
      <div class="footerSeparator" />
      <div>{{ $t('quiz') }}: {{ (quizTime / 1000).toFixed(2) }} {{ $t('seconds') }}</div>
    </div>
  </div>
</template>

<translations>
  memorize: 'Memorize'
  memorize_no: 'Memorer'
  quiz: 'Quiz'
  quiz_no: 'Quiz'
  seconds: 'sec'
  seconds_no: 'sek'
</translations>

<script>
import Test from './Test';

export default {
    components: { Test },
    props: {
        memorizeTime: {
            type: Number
        },
        quizTime: {
            type: Number
        },
        layout: {
            type: String,
            default: ''
        },
        initial: Object,
        tests: Array
    },
    computed: {
        empty() {
            return this.tests.length === 0;
        }
    }
};
</script>

<style scoped lang="scss">
.Item {
    // background-color: white;
    // border-radius: 0.5em;

    &.layout-initialOnSide {
        .body {
            display: flex;
        }
        .divider {
            // display: none;
            margin: 0 1em;
        }
        .tests {
            flex: 1;
        }
    }

    &.empty {
        .body {
            padding-bottom: 0;
        }
        .divider {
            margin-bottom: 0;
        }
    }
}
.body {
    background-color: white;
    padding: 1em;
    border-radius: 0.5em 0.5em 0 0;
}
.initial {
    font-size: 140%;
    color: rgba(black, 0.9);
}
.initial--Text {
    font-size: 100%;
}
.initialImage {
    $size: 3em;
    width: $size;
    height: $size;
    background-size: cover;
}
.Test {
    // &:not(:first-child) {
    margin-top: 1em;
    // }
}
.divider {
    height: 1px;
    background-color: mix(black, white, 5%);
    margin: 1em 0;
}
.footer {
    display: flex;
    color: rgba(black, 0.5);
    background-color: mix(black, white, 5%);
    padding: 1em;
    border-radius: 0 0 0.5em 0.5em;

    // Nicer?
    // background-color: rgba(black, 0.1);
    // color: rgba(white, 0.5);
}
.footerSeparator {
    width: 1px;
    align-self: stretch;
    background-color: rgba(black, 0.05);
    margin: 0 1em;
}
</style>
