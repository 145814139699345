<template>
  <div class="Fasit">
    <div class="body">
      <div
        v-if="type === 'image'"
        class="image"
        :style="{ backgroundImage: `url(${value.image})` }" />
      <div
        v-else-if="type === 'color'"
        class="color"
        :style="{ backgroundColor: value.color }" />
      <div
        v-else
        class="value">
        {{ value }}
      </div>

      <div
        v-if="value && value.label"
        class="value">
        {{ value.label }}
      </div>

      <div class="spacer" />

      <div class="fasitLabel">
        (fasit)
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        type: String,
        value: Object,
        score: Number
    }
};
</script>

<style scoped lang="scss">
.Fasit {
    color: rgba(black, 0.9);
}

.body {
    display: flex;
    align-items: center;
}

.image {
    $size: 3em;
    width: $size;
    height: $size;
    background-size: cover;
    margin-right: 1em;
}
.color {
    width: 4em;
    height: 2em;
    margin-right: 1em;
}

.value {
    font-size: 120%;
}

.spacer {
    flex: 1;
}
.label {
    color: rgba(black, 0.3);
    margin-bottom: 0.5em;
}
.fasitLabel {
    color: rgba(black, 0.3);
}
</style>
