<template>
  <div class="LevelFailView">
    <GameScrollableWithStickyHeader
      ref="scrollContainer"
      class="scrollContainer"
      :sticky-height="headerStickyHeight"
      @scroll="onHeaderScroll">
      <div
        slot="header"
        ref="header"
        class="header"
        :style="{ backgroundImage: `url(${theme.coverImage})` }">
        <div
          ref="title"
          v-t="'title'"
          class="title" />
        <div
          ref="subtitle"
          v-t="'subtitle'"
          class="subtitle"
          :style="{ color: theme.primaryColor }" />

        <div
          ref="targetHeightHelper"
          class="starsContainer targetHeightHelper">
          <div
            ref="stars"
            class="stars">
            <svgicon
              v-for="star of 3"
              :key="star"
              class="Star"
              icon="star" />
          </div>
        </div>
      </div>

      <div
        slot="body"
        ref="body"
        class="body">
        <div class="icon" />
        <div
          v-t="'text'"
          class="text" />

        <StandardSection
          class="readMoreSection"
          :label="$t('furtherReading')">
          <RelatedReading
            class="readingList"
            :game-id="gameId" />
        </StandardSection>

        <div class="spacer" />
        <div
          v-if="level !== null"
          ref="retryButton"
          class="retryButton --global-clickable"
          :style="{
            backgroundColor: theme.primaryColor,
            color: theme.textColorOnPrimaryColor,
            top: `${headerStickyHeight2}px`
          }"
          @click="retryLevel()">
          {{ $t('retryLevel', { level: level.groupIndex + 1, sublevel: level.localIndex + 1 }) }}
        </div>

        <StandardSection
          class="quizDetails"
          :label="$t('yourAnswers')">
          <SessionReport :items="sessionReport.items" />
        </StandardSection>
      </div>
    </GameScrollableWithStickyHeader>

    <GameHeader
      ref="top"
      class="top"
      :countdown="-1"
      @request-exit="exit()" />
  </div>
</template>

<translations>
  title: 'Game Over'
  title_no: 'Game Over'
  subtitle: 'Improve with the memory systems'
  subtitle_no: 'Bli bedre med huskesystemene'
  text: 'Ouch, you failed to remember all! Have fun using the memory systems to level up.'
  text_no: 'Oisann, du mislykkes i å huske alt! Ha det gøy med huskesystemene for å komme til neste nivå.'
  retryLevel: 'Retry level {level}.{sublevel}'
  retryLevel_no: 'Prøv nivå {level}.{sublevel} på nytt'
  furtherReading: 'Further reading'
  furtherReading_no: 'Videre lesing'
  yourAnswers: 'Your answers'
  yourAnswers_no: 'Dine svar'
</translations>

<script>
import { TimelineLite } from 'gsap';
import upperFirst from 'lodash-es/upperFirst';

import soundService from '@/services/sound-service';
import GameScrollableWithStickyHeader from '@/components/GameScrollableStickyHeader';

import GameHeader from '@/modules/games-shared/components/GameHeader';

import gameHelper from '@/modules/games-shared/game-helper';

import RelatedReading from '../components/RelatedReading';
import StandardSection from '../components/StandardSection';
import SessionReport from '../components/SessionReport';

export default {
    components: { RelatedReading, StandardSection, GameScrollableWithStickyHeader, SessionReport, GameHeader },
    props: {
        gameId: {
            type: String,
            required: true
        },
        levelIndex: {
            type: Number,
            required: true
        },
        sessionId: {
            type: String,
            required: true
        }
    },
    inject: ['theme'],
    data() {
        return {
            headerStickyHeight2: 0
        };
    },
    computed: {
        level() {
            return this.$store.state[`moduleGame${upperFirst(this.gameId)}`].levels[this.levelIndex];
        }
    },
    created() {
        this.sessionReport = gameHelper.sessions[this.sessionId].generateReport(this.$t.bind(this));
    },
    mounted() {
        this.headerStickyHeight2 = this.headerStickyHeight();

        let tl = new TimelineLite();
        tl.pause();
        tl.seek(0);

        // tl.staggerFromTo(this.$refs.header.children, 1, {
        //   opacity: 1
        // }, {
        //   opacity: 0
        // }, 0)
        // this.tl = tl

        tl.staggerFromTo(
            [this.$refs.title, this.$refs.subtitle],
            1,
            {
                opacity: 1
            },
            {
                opacity: 0
            },
            0
        );

        tl.fromTo(
            this.$refs.stars,
            1,
            {
                // opacity: 1,
                yPercent: 50,
                scale: 1
            },
            {
                // opacity: 0,
                yPercent: 0,
                scale: 0.7
            },
            0
        );

        tl.fromTo(
            this.$refs.body,
            1,
            {
                // opacity: 1,
                paddingTop: '2em'
            },
            {
                // opacity: 0,
                paddingTop: '0em'
            },
            0
        );
        this.tl = tl;

        this.$refs.scrollContainer.$el.addEventListener('scroll', this.onBodyScroll);

        soundService.play(this.theme.sounds.gameOverSound, { loop: true }, 'background');
    },
    beforeDestroy() {
        this.tl.kill();
        this.$refs.scrollContainer.$el.removeEventListener('scroll', this.onBodyScroll);
        soundService.stop(this.theme.sounds.gameOverSound, 500);
    },
    methods: {
        headerStickyHeight() {
            if (this.$refs.targetHeightHelper) {
                return this.$refs.targetHeightHelper.clientHeight;
            } else {
                return 0;
            }
        },
        onHeaderScroll(fraction) {
            this.tl.time(fraction);
        },
        onBodyScroll(event) {
            const offsetTop = this.$refs.retryButton.offsetTop;
            const topHeight = this.$refs.top.$el.clientHeight;
            if (event.target.scrollTop >= offsetTop - topHeight) {
                this.$refs.retryButton.classList.add('sticking');
            } else {
                this.$refs.retryButton.classList.remove('sticking');
            }
        },
        retryLevel() {
            this.$router.push({
                name: 'MemogeniusGame-LevelSession',
                levelIndex: this.levelIndex
            });
        },
        exit() {
            this.$router.push({
                name: 'MemogeniusGame-Levels'
            });
        }
    }
};
</script>

<style scoped lang="scss">
.LevelFailView {
    display: flex;
    flex-direction: column;

    $transitionDuration: 0.5s;
    &.banner_to_none-leave-active .body,
    &.banner_to_none-leave-active .header > * {
        transition: opacity $transitionDuration;
    }
    &.banner_to_none-leave-to .body,
    &.banner_to_none-leave-to .header > * {
        opacity: 0;
    }
}
.header {
    height: 12em;
    background-color: rgba(black, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-size: cover;
    background-position: center bottom;
}
.targetHeightHelper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $memogeniusShared-headerHeight;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title {
    font-size: 160%;
}
.subtitle {
    margin-top: 0.5em;
}
.stars {
    display: flex;
    justify-content: center;
}

.Star {
    $size: 3em;
    width: $size;
    height: $size;
    // fill: rgba(white, 0.1);
    fill: #464392; // Color for game-faces
    fill: none;
    stroke: rgba(white, 0.2);
    margin: 0 0.3em;

    &.isFull {
        fill: #f2c500;
    }
}

.body {
    padding: 2em;
    padding-top: 1em;
    // padding-top: 12em + 1em;
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // overflow-y: auto;
}
// .icon {
//   $size: 8em;
//   width: $size;
//   height: $size;
//   background-color: rgba(white, 0.5);
//   margin-top: -3em;
//
//   flex-shrink: 0;
// }
.text {
    line-height: 1.5em;
    margin: auto;
    margin-top: 2em;
    text-align: center;
    max-width: 20em;
    color: rgba(white, 0.8);
}

.readMoreSection {
    margin-top: 3em;
}

.spacer {
    flex: 1;
}
.retryButton {
    padding: 1em 2em;
    color: white;
    align-self: stretch;
    text-align: center;
    border-radius: 0.2em;

    margin-top: 2em;

    position: sticky;
    top: 12em;

    $transitionDuration: 0.2s;
    transition: margin $transitionDuration, border-radius $transitionDuration;

    &.sticking {
        margin-left: -2em;
        margin-right: -2em;
        border-radius: 0;
    }
}

.top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    justify-content: flex-end;
}

.quizDetails {
    margin-top: 3em;
}
</style>
