<template>
  <div class="SessionReport">
    <Item
      v-for="(item, i) of items"
      :key="i"
      v-bind="item" />
  </div>
</template>

<script>
import Item from './Item';

export default {
    components: { Item },
    props: {
        items: {
            type: Array
        }
    }
};
</script>

<style scoped lang="scss">
.SessionReport {
}
.Item {
    &:not(:first-child) {
        margin-top: 1em;
    }
}
</style>
