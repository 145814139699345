<template>
  <div class="Test">
    <div class="header">
      <div class="headerLine" />
      <div
        v-if="testName"
        class="label">
        {{ $t(`currentGame.testLabels.${testName}`) }}
      </div>
      <div class="headerLine" />
    </div>
    <div class="attempts">
      <Attempt
        v-for="(attempt, i) of attempts"
        :key="i"
        class="Attempt"
        v-bind="attempt" />
    </div>
    <Fasit
      v-if="fasit"
      class="fasit"
      v-bind="fasit" />
  </div>
</template>

<script>
import Attempt from './Attempt';
import Fasit from './Fasit';

export default {
    components: { Attempt, Fasit },
    props: {
        testName: {
            type: String
        },
        attempts: {
            type: Array
        },
        fasit: {
            type: Object
        }
    }
};
</script>

<style scoped lang="scss">
.header {
    display: flex;
    align-items: center;
    padding-bottom: 0.5em;
}
.headerLine {
    height: 1px;
    flex: 1;
    background-color: mix(black, white, 5%);
}
.label {
    color: rgba(black, 0.3);
    margin: 0 0.5em;
}
.Attempt {
    margin-top: 0.5em;
}
.fasit {
    margin-top: 0.5em;
}
</style>
