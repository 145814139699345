<template>
  <div
    v-if="!timeout"
    class="Attempt">
    <div
      v-if="!value"
      class="timeout">
      -- timeout --
    </div>
    <div
      v-else-if="type === 'image'"
      class="image"
      :style="{ backgroundImage: `url(${value.image})` }" />
    <div
      v-else-if="type === 'color'"
      class="color"
      :style="{ backgroundColor: value.color }" />
    <div
      v-else
      class="value">
      {{ value }}
    </div>

    <div
      v-if="value && value.label"
      class="value">
      {{ value.label }}
    </div>

    <div class="spacer" />

    <div
      class="score"
      :class="[score === 1 ? 'correct' : 'wrong']">
      <svgicon
        v-if="score === 1"
        class="correctIcon"
        icon="checkmark" />
      <svgicon
        v-else
        class="wrongIcon"
        icon="close" />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        type: String,
        value: Object,
        score: Number,
        timeout: Boolean
    }
};
</script>

<style scoped lang="scss">
.Attempt {
    display: flex;
    align-items: center;
    color: rgba(black, 0.9);
}

.image {
    $size: 3em;
    width: $size;
    height: $size;
    background-size: cover;
    margin-right: 1em;
}
.color {
    width: 4em;
    height: 2em;
    margin-right: 1em;
}

.value {
    font-size: 120%;
}

.spacer {
    flex: 1;
}

.score {
    $size: 1.2em;
    width: $size;
    height: $size;
    border-radius: 50%;
    margin-right: 0.5em;

    display: flex;
    justify-content: center;
    align-items: center;

    &.correct {
        background-color: $memogeniusShared-correctColor;
    }
    &.wrong {
        background-color: $memogeniusShared-wrongColor;
    }
}
.correctIcon {
    $size: 50%;
    width: $size;
    height: $size;
    fill: white;
}
.wrongIcon {
    $size: 50%;
    width: $size;
    height: $size;
    fill: white;
}
</style>
